import "./footer.scss";

interface FooterProps {
  title: string;
}

export function Footer(props: FooterProps) {
  const { title } = props;
  return (
    <div className="footer">
      <div className="footer-right">
        <label>{title}</label>
      </div>
    </div>
  );
}
