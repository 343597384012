import { useEffect, useState } from "react";
import "./createuser.scss";
import { Button } from "@medm-ui/button";
import moment from "moment";
import { User } from "../../model/user.model";
import AdminUserInfo from "../../service/adminUserService";
import { createUser, getUsers } from "../../service/userService";
import { useNavigate } from "react-router-dom";
import { Notification } from "../shared/notification";

export function CreateUser() {
  let navigate = useNavigate();

  const [formErrors, setFormErrors] = useState({
    userEmailIdValid: false,
    requestedByValid: false,
    accessStartDateTimeValid: false,
    accessEndDateTimeValid: true,
    formValid: false,
    validationError: {
      userEmailIdError: "",
      requestedByError: "",
      accessStartDateTimeError: "",
      accessEndDateTimeError: "",
    },
  });

  const [userInfo, setUserInfo] = useState({
    userEmailId: "",
    applicationId: "rfr-calculator",
    requestedBy: "",
    accessStartDateTime: "",
    accessEndDateTime: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [userCreated, setUserCreated] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");

  const [usersData, setUserData] = useState("" as any);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUsers();
      setUserData(data);
    };

    fetchData();
  }, []);

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    validateField(name, value);
    setUserInfo((state) => ({ ...state, [name]: value }));
  };

  const validateDate = (date: string) => {
    const dateFormat = "yyyy/MM/DD HH:mm:ss";
    return moment(
      moment(date).format("yyyy/MM/DD HH:mm:ss"),
      dateFormat,
      true
    ).isValid();
  };

  const validateEmail = (email: any) => {
    const regx = /^([\w'.+-]+)@([\w-]+\.)+([\w]{2,})$/i;
    return regx.test(email);
  };

  const validateField = (fieldName: any, value: any) => {
    switch (fieldName) {
        case "userEmailId":
            formErrors.userEmailIdValid = validateEmail(value);
            formErrors.validationError.userEmailIdError = formErrors.userEmailIdValid ? "" : "User Email ID is either null or not in valid format";
            if (value.length > 100) {
                formErrors.userEmailIdValid = false;
                formErrors.validationError.userEmailIdError = "Max 100 characters are allowed";
            }
            break;

        case "requestedBy":
            formErrors.requestedByValid = validateEmail(value);
            formErrors.validationError.requestedByError = formErrors.requestedByValid ? "" : "Requested By Email ID is either null or not in valid format";
            break;

        case "accessStartDateTime":
            handleAccessStartDateTimeValidation(value);
            break;

        case "accessEndDateTime":
            handleAccessEndDateTimeValidation(value);
            break;
    }

    updateFormValidity();
};

  const handleAccessStartDateTimeValidation = (value: any) => {
    if (validateDate(value) && value !== null) {
        const startDate = moment.utc(value);
        const endDate = moment.utc(userInfo.accessEndDateTime);
        if (userInfo.accessEndDateTime !== "" && startDate >= endDate) {
            formErrors.accessStartDateTimeValid = false;
            formErrors.validationError.accessStartDateTimeError = "Access Start DateTime can't be greater than or equal the Access End DateTime";
        } else {
            formErrors.accessStartDateTimeValid = true;
            formErrors.validationError.accessStartDateTimeError = "";
            formErrors.validationError.accessEndDateTimeError = "";
        }
    } else {
        formErrors.accessStartDateTimeValid = false;
        formErrors.validationError.accessStartDateTimeError = "Access Start DateTime is not valid";
    }
};

const handleAccessEndDateTimeValidation = (value: any) => {
    formErrors.accessEndDateTimeValid = validateDate(value);
    formErrors.validationError.accessEndDateTimeError = formErrors.accessEndDateTimeValid ? "" : "Access End DateTime is not valid";
    if (value !== null && moment.utc(value).format() <= moment.utc(userInfo.accessStartDateTime).format()) {
        formErrors.accessEndDateTimeValid = false;
        formErrors.validationError.accessEndDateTimeError = "Access End DateTime can't be less than or equal the Access Start DateTime";
    }
};

const updateFormValidity = () => {
    formErrors.formValid = (
        formErrors.userEmailIdValid &&
        formErrors.requestedByValid &&
        formErrors.accessStartDateTimeValid &&
        formErrors.accessEndDateTimeValid
    );
};

  const handleCancel = () => {
    navigate("/home");
  };

  const checkUserExists = async () => {
    const isUserExists = usersData.some((x: any) => {
      if (
        x.userId === userInfo.userEmailId &&
        x.applicationId === userInfo.applicationId
      ) {
        return true;
      }
    });
    return isUserExists;
  };

  const resetFormState = () => {
    setUserInfo({
      userEmailId: "",
      applicationId: "rfr-calculator",
      requestedBy: "",
      accessStartDateTime: "",
      accessEndDateTime: "",
    });
    setFormErrors({
      userEmailIdValid: false,
      requestedByValid: false,
      accessStartDateTimeValid: false,
      accessEndDateTimeValid: true,
      formValid: false,
      validationError: {
        userEmailIdError: "",
        requestedByError: "",
        accessStartDateTimeError: "",
        accessEndDateTimeError: "",
      },
    });

    formErrors.formValid = false;
  };

  const handleSubmit = async (event: any) => {
    const adminUserInfo = AdminUserInfo();

    if (adminUserInfo) {
      const isUserExists = await checkUserExists();
      if (isUserExists) {
        setErrorResponse("User already exists for this Application ID");
        setTimeout(() => {
          setErrorResponse("");
        }, 5000);
        return;
      }
      const userData: User = {
        userId: userInfo.userEmailId,
        applicationId: userInfo.applicationId,
        requestedBy: userInfo.requestedBy,
        startDate: moment(userInfo.accessStartDateTime).format(
          "yyyy/MM/DD HH:mm:ss"
        ),
        endDate:
          userInfo.accessEndDateTime !== ""
            ? moment(userInfo.accessEndDateTime).format("yyyy/MM/DD HH:mm:ss")
            : "",
        createdBy: adminUserInfo.email,
        createdOn: moment.utc().format("yyyy/MM/DD HH:mm:ss"),
        lastUpdatedBy: adminUserInfo.email,
        lastUpdatedOn: moment.utc().format("yyyy/MM/DD HH:mm:ss"),
      };
      setIsLoading(true);
      createUser(userData)
        .then(() => {
          setIsLoading(false);
          setUserCreated(true);
          resetFormState();
          setTimeout(() => {
            setUserCreated(false);
          }, 5000);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error !== null && error !== undefined) {
            if (error.data["error"].errors.length > 0) {
              let errorField = error.data["error"].errors[0].errorField;
              let errorDescription =
                error.data["error"].errors[0].errorDescription;
              setErrorResponse(errorField + " " + errorDescription);
            }
          } else {
            setErrorResponse("Error occurred while creating user");
          }
          setTimeout(() => {
            setErrorResponse("");
          }, 1000);
          console.error(error);
        });
    }
  };

  return (
    <div className="new-user">
      {isLoading && <div id="loading">Loading...</div>}
      {userCreated && <Notification type="success" message="User Created Successfully" />}
      {errorResponse !== "" && <Notification type="warning" message={errorResponse} />}
      <div className="page-header">
        <div className="left">
          <h3>New User</h3>
        </div>
        <div className="right">
          <Button
            data-test-id={"Create"}
            buttonId={"Create"}
            buttonLabel={"Create"}
            buttonType={"Primary"}
            disabled={!formErrors.formValid}
            showIcon={true}
            iconProps={{
              color: "Black",
              size: "20",
              icon: "plus",
            }}
            onButtonClick={handleSubmit}
            buttonSize={"small"}></Button>
          <Button
            data-test-id={"Cancel"}
            buttonId={"Cancel"}
            buttonLabel={"Cancel"}
            buttonType={"Secondary"}
            disabled={false}
            showIcon={true}
            iconProps={{
              color: "Black",
              size: "20",
              icon: "close",
            }}
            onButtonClick={handleCancel}
            buttonSize={"small"}></Button>
        </div>
      </div>
      <form name="testform" className="form" onSubmit={handleSubmit}>
        <div className="email">
          <h4>Email *</h4>
          <h6>
            Maximum of 100 characters, including the domain name (example.com).
            Email cannot be updated after the user creation.
          </h6>
          <input
            data-test-id={"email"}
            type="email"
            name="userEmailId"
            value={userInfo.userEmailId}
            onChange={handleChange}
            placeholder="User Email ID"
            required
          ></input>
        </div>
        <h3>User Access</h3>
        <h6>
          User Access Start DateTime and End DateTime are in UTC timezone.
        </h6>
        <div className="user-access">
          <div>
            <label>Application Id *</label>
            <select
              data-test-id={"applicationId"}
              id="applicationId"
              name="applicationId"
              value={userInfo.applicationId}
              onChange={handleChange}
              defaultValue="rfr-calculator"
            >
              <option value="rfr-calculator">Risk Free Rates</option>
              <option value="atlas-fdc3">Atlas Fdc3</option>
            </select>
          </div>
          <div>
            <label>Requested By *</label>
            <input
              data-test-id={"requestedBy"}
              id="requestedBy"
              type="email"
              name="requestedBy"
              value={userInfo.requestedBy}
              onChange={handleChange}
              placeholder="Requester Email Id"
            ></input>
          </div>
          <div>
            <label>Access Start DateTime *</label>
            <input
              data-test-id={"accessStartDateTime"}
              type="datetime-local"
              name="accessStartDateTime"
              value={userInfo.accessStartDateTime}
              onChange={handleChange}
              min="2020-01-01T00:00"
              max="9999-12-31T00:00"
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            ></input>
          </div>
          <div>
            <label>Access End DateTime (Optional)</label>
            <input
              id="accessEndDateTime"
              data-test-id="accessEndDateTime"
              type="datetime-local"
              name="accessEndDateTime"
              value={userInfo.accessEndDateTime}
              onChange={handleChange}
              min={userInfo.accessStartDateTime}
              max="9999-12-31T00:00"
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            ></input>
          </div>
        </div>
        {formErrors.formValid === false && (
          <div
            className="error"
            id="validationError"
            data-test-id="validationError"
          >
            <p>{formErrors.validationError.userEmailIdError}</p>
            <p>{formErrors.validationError.requestedByError}</p>
            <p>{formErrors.validationError.accessStartDateTimeError}</p>
            <p>{formErrors.validationError.accessEndDateTimeError}</p>
          </div>
        )}
      </form>
    </div>
  );
}
